import type ComboMenu from './combo-menu';

function adjustPageHeaderMarginAccordingScilogHeader (): void {
    const scilogMenu = document.querySelector('.scilog-header-navigation-container') as HTMLElement;
    if (scilogMenu === null || scilogMenu === undefined) return;

    const pageHeader = document.querySelector('.page-header') as HTMLElement;
    if (pageHeader === null || pageHeader === undefined) return;

    const menuHeight = scilogMenu.offsetHeight;

    pageHeader.style.marginTop = `${menuHeight}px`;
}

export function adjustScilogHeader (): void {
    adjustPageHeaderMarginAccordingScilogHeader();
    window.addEventListener('resize', () => {
        adjustPageHeaderMarginAccordingScilogHeader();
    });
}

export function handleScilogHeaderOnScroll (): void {
    const headerElement = document.querySelector('.scilog-header-navigation-container') as HTMLElement;

    if (headerElement === null || headerElement === undefined) return;

    const headerElementHeight: number = headerElement.clientHeight;
    const scrollTop: number = window.scrollY || document.documentElement.scrollTop;
    const bodyClass = 'body-scroll';

    if (scrollTop > headerElementHeight) {
        document.body.classList.add(bodyClass);
    } else {
        document.body.classList.remove(bodyClass);
    }
}

export function handleScilogDropdownLastElementLeave (event: KeyboardEvent, layer: ComboMenu): void {
    if (event.key === 'Tab' && !(event.shiftKey && event.key === 'Tab')) {
        layer.close();
    }
}
