import { createNanoEvents, type Emitter, type Unsubscribe } from 'nanoevents';

interface ComboMenuConfig {
    desktopMediaQuery?: MediaQueryList
    submenuSelector?: string
    menuToggleSelector?: string
    menuLinkSelector?: string
}

interface ComboMenuEvents {
    open: () => void
    close: () => void
}

class ComboMenu {
    private readonly containerElement!: HTMLElement;
    private readonly mainMenuElement!: HTMLElement | null;
    private readonly emitter!: Emitter;
    private readonly comboMenuToggleElement!: HTMLElement | null;
    private readonly desktopMediaQuery!: MediaQueryList;
    private readonly submenuSelector!: string;
    private readonly menuToggleSelector!: string;
    private readonly menuLinkSelector!: string;
    private isComboMenuOpen = false;
    private readonly mobileSlideKeyframes = [
        { translate: '100vw' },
        { translate: '0' }
    ];

    constructor (
        containerElement: HTMLElement | string,
        config: ComboMenuConfig = {
            submenuSelector: ':scope > ul',
            menuToggleSelector: ':scope > button',
            menuLinkSelector: ':scope > a'
        }
    ) {
        if (typeof containerElement === 'string') {
            const element = document.querySelector<HTMLElement>(containerElement);
            if (element) {
                this.containerElement = element;
            } else {
                console.error(`Element '${containerElement}' not found.`);
                return;
            }
        } else if (containerElement instanceof HTMLElement) {
            this.containerElement = containerElement;
        } else {
            console.error('Not an HTML element: ' + typeof containerElement);
            return;
        }

        this.mainMenuElement = this.containerElement.querySelector('.main-menu');
        this.comboMenuToggleElement = this.containerElement.querySelector('.header-mobile-menu-toggle'); // TODO do not hard code class

        this.emitter = createNanoEvents<ComboMenuEvents>();

        if (config.desktopMediaQuery) {
            this.desktopMediaQuery = config.desktopMediaQuery;
        } else {
            this.desktopMediaQuery = window.matchMedia('(min-width: 1200px)');
        }

        if (config.submenuSelector !== undefined) {
            this.submenuSelector = config.submenuSelector;
        }

        if (config.menuToggleSelector !== undefined) {
            this.menuToggleSelector = config.menuToggleSelector;
        }

        if (config.menuLinkSelector !== undefined) {
            this.menuLinkSelector = config.menuLinkSelector;
        }

        this.init();
    }

    static attach (containerElement: HTMLElement, config: ComboMenuConfig = {}): ComboMenu {
        return new ComboMenu(containerElement, config);
    }

    on<E extends keyof ComboMenuEvents>(event: E, callback: ComboMenuEvents[E]): Unsubscribe {
        return this.emitter.on(event, callback);
    }

    private init (): void {
        this.comboMenuToggleElement?.addEventListener('click', this.comboMenuToggleClickHandler.bind(this));

        this.decorateMenu(this.containerElement.querySelectorAll('ul.main-menu-primary > li'), 1);

        this.desktopMediaQuery.addEventListener('change', this.desktopMediaQueryChangeHandler.bind(this));

        document.addEventListener('click', this.comboMenuCheckCollapse.bind(this));
    }

    private decorateMenu (menuElements: NodeListOf<HTMLElement>, level: number): void {
        menuElements.forEach((menuElement) => {
            // A elements could be allowed as toggles, but must be distinguished from real links
            const toggleElement = menuElement.querySelector<HTMLElement>(this.menuToggleSelector);
            const linkElement = menuElement.querySelector<HTMLElement>(this.menuLinkSelector);

            if (toggleElement?.ariaExpanded === null) {
                toggleElement.ariaExpanded = 'false';
            }

            if (toggleElement && !linkElement && level === 1) {
                toggleElement.addEventListener('click', this.toggleMegaMenu.bind(this));
            }

            if (toggleElement && linkElement) {
                toggleElement.addEventListener('click', this.toggleMenuItemClickHandler.bind(this));
            }

            const submenuElements = menuElement.querySelectorAll<HTMLElement>(this.submenuSelector);
            for (const submenuElement of submenuElements) {
                submenuElement.classList.add(`combo-menu-level-${level}`);
                this.decorateMenu(submenuElement.querySelectorAll(':scope > li'), level + 1);
            }
        });
    }

    private toggleMegaMenu (event: MouseEvent): void {
        event.preventDefault();
        const toggleElement = event.currentTarget as HTMLButtonElement;

        if (toggleElement.ariaExpanded === 'true') {
            if (this.isDesktop()) {
                this.close();
            }
            toggleElement.ariaExpanded = 'false';
        } else {
            const menuItems: NodeListOf<HTMLButtonElement> | undefined
                = this.mainMenuElement?.querySelectorAll('.main-menu-primary > li > button');
            if (menuItems) {
                menuItems.forEach((menuItem) => {
                    menuItem.ariaExpanded = 'false';
                });
            }
            this.open();
            toggleElement.ariaExpanded = 'true';
        }
    }

    private toggleMenuItem (item: HTMLElement): void {
        const menuItems = this.mainMenuElement?.querySelectorAll('.main-menu-primary li');
        if (menuItems) {
            menuItems.forEach((menuItem) => {
                menuItem.classList.remove('active');
            });
        }
        if (item.getAttribute('aria-expanded') === 'true') {
            item.setAttribute('aria-expanded', 'false');
        } else {
            this.mainMenuElement?.querySelectorAll('.main-menu-primary button').forEach((menuButton) => {
                if (this.isDesktop()) {
                    if (!menuButton.classList.contains('combo-menu-nav-icon-wrapper')) {
                        const ulMenu = menuButton.parentElement?.querySelector(':scope > ul');
                        if (ulMenu && !ulMenu?.contains(item as Node)) {
                            menuButton.setAttribute('aria-expanded', 'false');
                        }
                    }
                } else {
                    const ulMenu = menuButton.parentElement?.querySelector(':scope > ul');
                    if (ulMenu && !ulMenu?.contains(item as Node)) {
                        menuButton.setAttribute('aria-expanded', 'false');
                    }
                }
            });
            item.setAttribute('aria-expanded', 'true');
            item.parentElement?.classList.add('active');
        }
    }

    private comboMenuToggleClickHandler (event: MouseEvent): void {
        this.toggle();
        event.preventDefault();
    }

    private comboMenuCheckCollapse (event: Event): void {
        if (this.containerElement.contains(event.target as Node)
            || (this.comboMenuToggleElement?.contains(event.target as Node) ?? false)) {
            return;
        }

        if (this.isDesktop()) {
            const mainMenu = this.mainMenuElement?.querySelector('.main-menu-primary');
            if (mainMenu && this.isComboMenuOpen && !mainMenu.matches(':hover')) {
                this.toggle();
                event.preventDefault();
            }
        }
    }

    private toggleMenuItemClickHandler (event: MouseEvent): void {
        this.toggleMenuItem(event.currentTarget as HTMLElement);
        event.preventDefault();
    }

    private desktopMediaQueryChangeHandler (event: MediaQueryListEvent): void {
        if (event.matches && this.isComboMenuOpen) {
            // TODO do not hard code query
            this.containerElement.querySelectorAll('ul.main-menu-primary > li > button').forEach((menuElement) => {
                menuElement.setAttribute('aria-expanded', 'true');
            });
        }
    }

    public isDesktop (): boolean {
        return this.desktopMediaQuery.matches;
    }

    public isOpen (): boolean {
        return this.isComboMenuOpen;
    }

    public open (): void {
        if (this.isOpen()) {
            return;
        }

        document.body.classList.add('main-menu-open', 'desktop-flyout-open', 'no-scroll');
        this.comboMenuToggleElement?.setAttribute('aria-expanded', 'true');

        if (!this.isDesktop() && this.mainMenuElement) {
            this.mainMenuElement.animate(this.mobileSlideKeyframes, {
                easing: 'ease-out',
                duration: 500
            });
        }

        this.isComboMenuOpen = true;
        this.emitter.emit('open', this);
    }

    public close (): void {
        if (!this.isOpen()) {
            return;
        }

        if (this.isDesktop()) {
            document.body.classList.remove('main-menu-open', 'desktop-flyout-open');
            this.comboMenuToggleElement?.setAttribute('aria-expanded', 'false');

            this.containerElement.querySelectorAll('ul.main-menu-primary > li > button').forEach((menuElement) => {
                menuElement.setAttribute('aria-expanded', 'false');
            });
        } else {
            if (this.mainMenuElement) {
                this.mainMenuElement.animate(this.mobileSlideKeyframes, {
                    direction: 'reverse',
                    easing: 'ease-out',
                    duration: 500
                }).finished.then(() => {
                    document.body.classList.remove('main-menu-open');
                    this.comboMenuToggleElement?.setAttribute('aria-expanded', 'false');
                    return undefined;
                }).catch(() => { // eslint-disable-line @typescript-eslint/no-empty-function
                });
            }
        }

        document.body.classList.remove('no-scroll');

        this.isComboMenuOpen = false;
        this.emitter.emit('close');
    }

    public toggle (): void {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    }
}

export default ComboMenu;
