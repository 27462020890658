class LightCone {
    private readonly element: HTMLElement;
    private readonly portraitPath = 'm 5.1340811,-0.30112203 c 0.8356545,-0.47553156 0.8349058,-1.24611147 -0.0019,-1.72101137 -0.8244229,-0.4700553 -2.1700062,-0.4751101 -3.0056607,-0.011373 -0.00749,0.00421 -0.014977,0.00821 -0.022088,0.012425 -0.1969325,0.1118285 -0.3534309,0.2442937 -0.4605085,0.3898178 0,0 -1.52342199,2.08682035 -1.52454529,2.08871446 -0.0621487,0.0932962 -0.043054,0.21038889 0.108577,0.29694369 0.150882,0.0861351 0.3575492,0.0977179 0.52190946,0.0627585 0.00412,-6.0188e-4 3.69866943,-0.8560827 3.69866943,-0.8560827 0.2568364,-0.061283 0.4897117,-0.15036727 0.6855216,-0.2621939';
    private readonly landscapePath = 'm 2.1112131,-0.49167211 c 0.324379,-0.58166569 0.324088,-1.52409599 -7.47e-4,-2.10503429 -0.324839,-0.5809359 -0.851077,-0.5802769 -1.17545607,0.00133 -0.07641,0.1367923 -0.13708,0.2986607 -0.178712,0.4767062 0,0 -0.59140101,2.55253071 -0.59180601,2.55466908 -0.02418,0.1141602 -0.01676,0.2574191 0.04211,0.3632932 0.05854,0.1052747 0.138868,0.1195708 0.202649,0.076789 0.0016,-6.641e-4 1.43591208,-1.04723178 1.43591208,-1.04723178 0.09971,-0.0749126 0.190047,-0.1839045 0.266056,-0.32058065';

    constructor (element: HTMLElement) {
        this.element = element;

        window.addEventListener('load', this.update.bind(this));
        new ResizeObserver(this.update.bind(this)).observe(this.element);
    }

    private update (): void {
        // See _page-header.scss for the SVG path
        const svgPath = this.element.querySelector('#page-header-big-light-cone path');
        if (svgPath) {
            if (window.matchMedia('(min-width: 768px)').matches) {
                svgPath.setAttribute('d', this.landscapePath);
            } else {
                svgPath.setAttribute('d', this.portraitPath);
            }
        }
    }

    static create (): void {
        const element = document.querySelector<HTMLElement>('.page-header');
        if (element) {
            (new LightCone(element)).update();
        }
    }
}

export default LightCone;
