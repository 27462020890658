export function addHoverEffectToContainerFundsElements (): void {
    const animatedImages: NodeListOf<HTMLElement> = document.querySelectorAll('.funds-image');
    for (const animatedImage of animatedImages) {
        // These are 'object' tags, no 'img' tags
        const originalSource = animatedImage.getAttribute('data') as string;
        const hoverSource = animatedImage.dataset.hover as string;
        animatedImage.addEventListener('mouseover', () => {
            animatedImage.setAttribute('data', hoverSource);
        });
        animatedImage.addEventListener('mouseout', () => {
            animatedImage.setAttribute('data', originalSource);
        });
    }
}
