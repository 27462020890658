import '../scss/common.scss'; // eslint-disable-line import/no-relative-parent-imports
import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import AccordionElement from './vue/AccordionElement.vue';
import SevenStepsElement from './vue/SevenStepsElement.vue';
import SlCheckbox from '@shoelace-style/shoelace/dist/components/checkbox/checkbox';
import SlOption from '@shoelace-style/shoelace/dist/components/option/option';
import SlRadio from '@shoelace-style/shoelace/dist/components/radio/radio';
import SlRadioGroup from '@shoelace-style/shoelace/dist/components/radio-group/radio-group';
import SlSelect from '@shoelace-style/shoelace/dist/components/select/select';
import SlTab from '@shoelace-style/shoelace/dist/components/tab/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/components/tab-group/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel';
import SlTextarea from '@shoelace-style/shoelace/dist/components/textarea/textarea';
import SlTooltip from '@shoelace-style/shoelace/dist/components/tooltip/tooltip';
import Ukiyo from 'ukiyojs'; // the parallax effect library
import initHeader from './modules/menu';
import axios from 'axios';
import { addToggleFunctionalityToEventFormCheckboxes } from './modules/form/form-checkboxes';
import { addHoverEffectToContainerFundsElements } from './modules/funds';
import { addHoverEffectToContainerProgramTracksElements } from './modules/program-tracks';
import ScrollProgress from './modules/scroll-progress';
import { adjustScilogHeader, handleScilogHeaderOnScroll } from './modules/scilog-menu';
import LightCone from './modules/light-cone';

function initAjaxForms (): void {
    function extractPrefix (key: string): string | null {
        const pattern = /tx_form_formframework\[[^\]]+\]/;
        const match = pattern.exec(key);

        return match ? match[0] : null;
    }

    document.querySelectorAll<HTMLElement>('.newsletter-form-wrapper form[data-ajaxuri]').forEach((form: HTMLElement) => {
        const target = form.dataset.ajaxuri as string;
        form.addEventListener('submit', (event) => {
            event.preventDefault();

            void (async () => {
                try {
                    const formData = new FormData(form as HTMLFormElement);
                    const prefix: string = Array.from(formData.keys())
                        .map((key) => extractPrefix(key))
                        .find((match) => match !== null) ?? '';
                    if (prefix) {
                        formData.append(`${prefix}[__currentPage]`, '1');
                    }
                    const response = await axios.post(target, formData);

                    if (response.status === 200) {
                        // eslint-disable-next-line require-atomic-updates
                        form.outerHTML = `<p>${response.data as string}</p>`;
                    }
                } catch (error) {
                    // Ignore
                }
                initAjaxForms();
            })();
        });
    });
}

function workOnDom (): void {
    // only here to prevent Webpack to remove the modules because they do not declare to have side effects
    const shoelaceModules = { // eslint-disable-line @typescript-eslint/no-unused-vars
        SlCheckbox,
        SlOption,
        SlRadio,
        SlRadioGroup,
        SlSelect,
        SlTab,
        SlTabGroup,
        SlTabPanel,
        SlTextarea,
        SlTooltip
    };

    const swiperElements = document.querySelector('.swiper');
    if (swiperElements !== null) {
        void import('./modules/swiper-manager').then(() => {
            // TODO make module side-effect free, possibly by calling an init function with the found swiper elements
            return true;
        });
    }

    for (const accordionElement of document.querySelectorAll('.accordion')) {
        const accordionHeaders = accordionElement.querySelectorAll('.accordion-header');
        const accordionContents = accordionElement.querySelectorAll('.accordion-content');

        createApp(AccordionElement, {
            headers: Array.from(accordionHeaders, (el) => (el.textContent as string).trim()),
            contents: Array.from(accordionContents, (el) => el.innerHTML.trim())
        })
            .use(PrimeVue)
            .mount(accordionElement);
    }

    for (const sevenStepsElement of document.querySelectorAll('.seven-steps-items')) {
        const sevenStepsIcons = sevenStepsElement.querySelectorAll('.seven-steps-icon');
        const sevenStepsHeaders = sevenStepsElement.querySelectorAll('.seven-steps-header');
        const sevenStepsContents = sevenStepsElement.querySelectorAll('.seven-steps-content');

        createApp(SevenStepsElement, {
            icons: Array.from(sevenStepsIcons, (el) => el.innerHTML.trim()),
            headers: Array.from(sevenStepsHeaders, (el) => el.innerHTML.trim()),
            contents: Array.from(sevenStepsContents, (el) => el.innerHTML.trim())
        })
            .use(PrimeVue)
            .mount(sevenStepsElement);
    }

    for (const container of document.querySelectorAll('.container-project-story')) {
        const swiperSlides = container.querySelectorAll('.swiper-slide');
        const accordionTabs = container.querySelectorAll('.p-accordion-tab');

        swiperSlides.forEach((slide, slideIndex) => {
            const exploreMoreLink = slide.querySelector('.card-link a');
            if (exploreMoreLink) {
                exploreMoreLink.addEventListener('click', (event) => {
                    event.preventDefault();
                    const correspondingAccordionTab = accordionTabs[slideIndex];
                    if (!correspondingAccordionTab.classList.contains('p-accordion-tab-active')) {
                        openAccordionTab(correspondingAccordionTab);
                    }
                    scrollToAccordionTab(correspondingAccordionTab);
                });
            }
        });
    }

    function openAccordionTab (tabElement: Element): void {
        const headerLink = tabElement.querySelector('.p-accordion-header-link');
        if (headerLink) {
            headerLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
        }
    }

    function scrollToAccordionTab (tabElement: Element): void {
        const header = document.querySelector('.header-navigation-container');
        const headerHeight = header ? header.clientHeight : 0;

        window.scrollTo({
            top: tabElement.getBoundingClientRect().top + window.scrollY - headerHeight,
            behavior: 'smooth'
        });
    }

    const mixedBackgroundElements = document.querySelector('.scheme-mixed');
    if (mixedBackgroundElements !== null) {
        void import('./modules/mixed-background').then(() => {
            // TODO make module side-effect free
            return true;
        });
    }

    // claim content element
    document.querySelectorAll<HTMLElement>('.ticker-item').forEach((tickerItem) => {
        let tickerCounter = 0;
        const maxLength = Math.round(tickerItem.offsetWidth / 4);
        setInterval(() => {
            tickerCounter--;
            tickerItem.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + tickerCounter.toString() + ', 0, 0, 1)';
            if (tickerCounter === -maxLength) {
                tickerCounter = 0;
            }
        }, 3);
    });

    // the parallax effect
    const reducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: no-preference)');
    if (reducedMotionMediaQuery.matches) {
        void new Ukiyo(document.querySelectorAll('.spotlight-image-desktop'), { scale: 1.25, speed: 3 });
    } else {
        document.querySelectorAll<HTMLImageElement>('.spotlight-image-desktop').forEach(
            (image) => (image.style.margin = '-25% 0 25%')
        );
    }

    document.querySelectorAll<HTMLElement>('.video-wrapper').forEach((embeddedVideo) => {
        // create an observer instance
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === 'childList' && mutation.target instanceof HTMLElement) {
                    const containsClass = mutation.target.children.item(0)?.classList.contains('uc-embedding-container') ?? false;
                    if (containsClass) {
                        mutation.target.classList.add('ucdisplay');
                    } else {
                        mutation.target.classList.remove('ucdisplay');
                    }
                }
            });
        });

        // configuration of the observer
        const config = { childList: true };

        // pass in the target node, as well as the observer options
        observer.observe(embeddedVideo, config);

        const containsClass = embeddedVideo.children.item(0)?.classList.contains('uc-embedding-container') ?? false;
        if (containsClass) {
            embeddedVideo.classList.add('ucdisplay');
        }
    });

    // Trigger click on activating a link element with role="button" by pressing SPACE
    document.querySelectorAll<HTMLElement>('a[role="button"]').forEach((linkAsButton) => {
        linkAsButton.addEventListener('keydown', (event) => {
            if (event.key === ' ' || event.code === 'Space') {
                event.preventDefault();
                event.stopPropagation();
                linkAsButton.click();
            }
        });
    });

    // Init lightbox for text media elements
    const lightboxElements = document.querySelectorAll<HTMLElement>('.image-lightbox-wrapper');

    if (lightboxElements !== null) {
        void import('./modules/lightbox').then((lightbox) => {
            lightbox.init(lightboxElements);
            return undefined;
        });
    }

    addToggleFunctionalityToEventFormCheckboxes();

    addHoverEffectToContainerFundsElements();

    addHoverEffectToContainerProgramTracksElements();

    ScrollProgress.create();

    LightCone.create();

    adjustScilogHeader();

    initHeader();
    initAjaxForms();
    window.initCardWidgets();
    window.initTeaserWidgets();

    window.addEventListener('scroll', handleScilogHeaderOnScroll);
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        workOnDom();
    });
} else {
    workOnDom();
}
