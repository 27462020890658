export function addHoverEffectToContainerProgramTracksElements (): void {
    const images: NodeListOf<HTMLElement> = document.querySelectorAll('.program-track-image');
    for (const image of images) {
        const originalSource = image.getAttribute('src') as string;
        const hoverSource = image.dataset.hover as string;

        image.addEventListener('mouseover', () => {
            image.setAttribute('src', hoverSource);
        });
        image.addEventListener('mouseout', () => {
            image.setAttribute('src', originalSource);
        });
    }
}
