export function addToggleFunctionalityToEventFormCheckboxes (): void {
    const eventRegistrationRequired = document.querySelector('.event-registration-required-checkbox') as HTMLInputElement;
    const eventRegistrationFieldSets = document.querySelector('.event-registration-required-fields') as HTMLElement;
    if (eventRegistrationFieldSets !== null) {
        eventRegistrationFieldSets.style.display = 'none';

        if (eventRegistrationRequired !== null) {
            eventRegistrationRequired.addEventListener('click', function () {
                eventRegistrationFieldSets.style.display = eventRegistrationRequired.checked ? 'block' : 'none';
            });
        }
    }

    const eventFreeEntry = document.querySelector('.event-free-entry-checkbox') as HTMLInputElement;
    const eventPaidEntry = document.querySelector('.event-paid-entry-checkbox') as HTMLInputElement;
    const eventCostsFields = document.querySelector('.event-costs-fields') as HTMLElement;
    if (eventCostsFields !== null) {
        eventCostsFields.style.display = 'none';

        if (eventFreeEntry !== null && eventPaidEntry !== null) {
            eventFreeEntry.addEventListener('click', function () {
                eventCostsFields.style.display = eventFreeEntry.checked ? 'none' : 'block';
                eventPaidEntry.checked = !eventFreeEntry.checked;
            });

            eventPaidEntry.addEventListener('click', function () {
                eventCostsFields.style.display = eventPaidEntry.checked ? 'block' : 'none';
                eventFreeEntry.checked = !eventPaidEntry.checked;
            });
        }
    }
}
