function getBreakpointValue (breakpoint: string): string {
    return getComputedStyle(document.querySelector(':root') as Element).getPropertyValue(`--breakpoint-${breakpoint}`);
}

export function getMediaQueryForBreakpointDown (breakpoint: string): MediaQueryList {
    return window.matchMedia(`(max-width: ${getBreakpointValue(breakpoint)}px)`);
}

export function getMediaQueryForBreakpointUp (breakpoint: string): MediaQueryList {
    return window.matchMedia(`(min-width: ${parseInt(getBreakpointValue(breakpoint)) - 0.02}px)`);
}
