class ScrollProgress {
    private readonly mobileMediaQuery!: MediaQueryList;
    private readonly darkBackgroundElements!: NodeListOf<Element>;

    constructor () {
        this.mobileMediaQuery = window.matchMedia('(max-width: 576px)');
        this.darkBackgroundElements = document.querySelectorAll('.scheme-dark:not(body>footer)');
    }

    private isMobile (): boolean {
        return this.mobileMediaQuery.matches;
    }

    private handleScrollingElementColors (footer: HTMLElement, scrollProgressIndicator: HTMLElement, scrollIconContainer: HTMLElement, scrollArrow: HTMLElement): void {
        if (this.isMobile()) {
            return;
        }

        const degrees = this.getScrollDegrees(footer.offsetHeight);
        let isIntersectingWithDarkScheme = false;
        for (const darkBackgroundElement of this.darkBackgroundElements) {
            if (this.areIntersecting(scrollProgressIndicator.getBoundingClientRect(), darkBackgroundElement.getBoundingClientRect())) {
                isIntersectingWithDarkScheme = true;
                break;
            }
        }

        if (isIntersectingWithDarkScheme) {
            scrollProgressIndicator.style.background = `conic-gradient(var(--color-light) ${degrees}deg, transparent ${degrees}deg)`;
            scrollIconContainer.style.backgroundColor = 'var(--color-dark)';
            scrollArrow.style.stroke = 'var(--color-light)';

            return;
        }

        scrollProgressIndicator.style.background = `conic-gradient(var(--color-dark) ${degrees}deg, transparent ${degrees}deg)`;
        scrollIconContainer.style.backgroundColor = 'white';
        scrollArrow.style.stroke = 'var(--primary-color)';
    }

    private adjustScrollingElementPosition (scrollingElement: HTMLElement, footer: HTMLElement, scrollIconContainer: HTMLElement, scrollArrow: HTMLElement): void {
        const footerTop = footer.getBoundingClientRect().top;
        const scrollingElementHeight = scrollingElement.offsetHeight;
        const initialTopHeightPercentage = 70;
        const initialTopHeightInPx = (window.innerHeight * initialTopHeightPercentage) / 100;

        if (footerTop < initialTopHeightInPx + scrollingElementHeight + 20) {
            scrollingElement.style.top = `${footerTop - scrollingElementHeight - 50}px`;
            scrollIconContainer.style.backgroundColor = 'var(--color-light)';
            scrollArrow.style.stroke = 'var(--primary-color)';

            return;
        }

        scrollingElement.style.top = `${initialTopHeightPercentage}%`;
    }

    private getScrollDegrees (footerHeight: number): number {
        const scrollTop = window.scrollY;
        const docHeight = document.body.offsetHeight - footerHeight;
        const winHeight = window.innerHeight;
        const scrollPercent = scrollTop / (docHeight - winHeight);
        return scrollPercent * 360;
    }

    private areIntersecting (indicatorBounds: DOMRect, targetElementBounds: DOMRect): boolean {
        const areIntersectingVertically = indicatorBounds.top < targetElementBounds.bottom && indicatorBounds.bottom > targetElementBounds.top;
        const areIntersectingHorizontally = indicatorBounds.left < targetElementBounds.right;

        return areIntersectingVertically && areIntersectingHorizontally;
    }

    private scrollToTop (event: Event): void {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    public addScroll (): void {
        const scrollingElement = document.querySelector('.scroll-element') as HTMLElement;
        const scrollingElementMobile = document.querySelector('.scroll-element-mobile') as HTMLElement;
        const scrollIconContainer = document.querySelector('.scroll-progress-icon-container') as HTMLElement;
        const scrollArrow = document.querySelector('svg.scroll-arrow') as HTMLElement;
        const scrollProgressIndicator = document.querySelector('.scroll-progress-indicator') as HTMLElement;
        const footer = document.querySelector('body>footer') as HTMLElement;

        if (footer !== null && scrollingElement !== null) {
            window.onscroll = () => {
                if (!this.isMobile()) {
                    this.handleScrollingElementColors(footer, scrollProgressIndicator, scrollIconContainer, scrollArrow);
                    this.adjustScrollingElementPosition(scrollingElement, footer, scrollIconContainer, scrollArrow);
                }
            };
        }

        scrollingElement?.addEventListener('click', (event: Event) => { this.scrollToTop(event); });
        scrollingElementMobile?.addEventListener('click', (event: Event) => { this.scrollToTop(event); });
    }

    static create (): void {
        (new ScrollProgress()).addScroll();
    }
}

export default ScrollProgress;
